import '@babel/polyfill';
import React from 'react';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';

import App from './App';

const isServer = typeof window === 'undefined';
const root = document.getElementById('root');
const appProps = isServer ? [] : window.CMS_DATA;

// Wait on code-split components to be ready before rendering
loadableReady(() => {
  hydrate(<App {...appProps} />, root);
});

if (module.hot) {
  module.hot.accept();
}
