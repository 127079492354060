const baseColors = {
  black: '#000',
  transparent: 'transparent',
  white: '#fff'
};

const primaryColors = {
  avocado: '#588300', // green
  avocadoDark: '#709A34', // green dark
  avocadoLight: '#A3CD67', // green light
  kaleLeaf: '#008661', // teal
  kaleLeafDark: '#0C705C', // teal dark
  kaleLeafLight: '#58BCA8', // teal light
  redPepper: '#E71B00', // orange
  redPepperDark: '#B23900', // orange/red - dark orange
  redPepperLight: '#FF864D' // orange/pink - light orange
};

const secondaryColors = {
  blueCheeseDark: '#AEDAD5', // coral
  blueCheeseLight: '#E7F3EF', // light coral
  chipotleRanch: '#EED7AB', // tan
  corn: '#EEB828', // yellow
  feta: '#F4F3E3' // light tan
};

const textColors = {
  blackBean: '#3A3B38', // dark grey
  peppercorn: '#5D5F60', // grey
  peppercornLight: '#8E908E' // semi-light grey
};

export const color = {
  ...baseColors,
  ...primaryColors,
  ...secondaryColors,
  ...textColors
};

export const fontFamily = {
  primary: "'GT Eesti', Helvetica, Arial, sans-serif"
};

export const fontWeight = {
  bold: '700',
  regular: '400',
  ultraBold: '900'
};

export const fontStyle = {
  italic: 'italic',
  normal: 'normal'
};

export const fontSize = {
  button: '18px',
  h1: '56px',
  h2: '48px',
  h3: '40px',
  h4: '32px',
  h5: '24px',
  h6: '20px',
  label: '14px',
  p: '18px',
  specChar: '50%'
};

export const lineHeight = {
  button: '18px',
  h1: '56px',
  h2: '48px',
  h3: '40px',
  h4: '40px',
  h5: '28px',
  h6: '28px',
  label: '28px',
  p: '28px'
};

export const letterSpacing = {
  button: '1.69px',
  h1: '0.32px',
  h2: '0.5px',
  h3: '0.5px',
  h4: '0.5px',
  h5: '0.5px',
  h6: '0.5px',
  label: '0',
  p: '0'
};

export const textTransform = {
  button: 'uppercase',
  h1: 'none',
  h2: 'none',
  h3: 'none',
  h4: 'none',
  h5: 'none',
  h6: 'none',
  label: 'none',
  p: 'none'
};

const baseSpacing = {
  horizontalPadding: 18,
  verticalPadding: 120
};

export const gridSettings = {
  columnWidth: 70,
  gutterWidth: 36,
  maxWidth: 1236,
  totalColumns: 12
};

export const grid = {
  maxWidth: gridSettings.maxWidth + baseSpacing.horizontalPadding * 2 + 'px',
  width: gridSettings.maxWidth + 'px'
};

export const spacing = {
  horizontalPadding: baseSpacing.horizontalPadding + 'px',
  verticalPadding: baseSpacing.verticalPadding + 'px',
  verticalPaddingHalf: baseSpacing.verticalPadding / 2 + 'px'
};

export const timing = {
  default: '400ms',
  fast: '200ms',
  slow: '800ms'
};

export const easing = {
  ease: 'ease',
  easeIn: 'ease-in',
  easeInOut: 'ease-in-out',
  easeOut: 'ease-out',
  linear: 'linear'
};

export const type = {
  button: {
    fontFamily: fontFamily.primary,
    fontSize: fontSize.button,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.bold,
    letterSpacing: letterSpacing.button,
    lineHeight: lineHeight.button,
    textTransform: textTransform.button
  },
  h1: {
    fontFamily: fontFamily.primary,
    fontSize: fontSize.h1,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.ultraBold,
    letterSpacing: letterSpacing.h1,
    lineHeight: lineHeight.h1,
    textTransform: textTransform.h1
  },
  h2: {
    fontFamily: fontFamily.primary,
    fontSize: fontSize.h2,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.ultraBold,
    letterSpacing: letterSpacing.h2,
    lineHeight: lineHeight.h2,
    textTransform: textTransform.h2
  },
  h3: {
    fontFamily: fontFamily.primary,
    fontSize: fontSize.h3,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.ultraBold,
    letterSpacing: letterSpacing.h3,
    lineHeight: lineHeight.h3,
    textTransform: textTransform.h3
  },
  h4: {
    fontFamily: fontFamily.primary,
    fontSize: fontSize.h4,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.bold,
    letterSpacing: letterSpacing.h4,
    lineHeight: lineHeight.h4,
    textTransform: textTransform.h4
  },
  h5: {
    fontFamily: fontFamily.primary,
    fontSize: fontSize.h5,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.ultraBold,
    letterSpacing: letterSpacing.h5,
    lineHeight: lineHeight.h5,
    textTransform: textTransform.h5
  },
  h6: {
    fontFamily: fontFamily.primary,
    fontSize: fontSize.h6,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.bold,
    letterSpacing: letterSpacing.h6,
    lineHeight: lineHeight.h6,
    textTransform: textTransform.h6
  },
  label: {
    fontFamily: fontFamily.primary,
    fontSize: fontSize.label,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.regular,
    letterSpacing: letterSpacing.label,
    lineHeight: lineHeight.label,
    textTransform: textTransform.label
  },
  p: {
    fontFamily: fontFamily.primary,
    fontSize: fontSize.p,
    fontStyle: fontStyle.normal,
    fontWeight: fontWeight.regular,
    letterSpacing: letterSpacing.p,
    lineHeight: lineHeight.p,
    textTransform: textTransform.p
  }
};

export default {
  color,
  easing,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  grid,
  gridSettings,
  letterSpacing,
  lineHeight,
  spacing,
  textTransform,
  timing,
  type
};
